import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, filter, first, take, takeUntil } from 'rxjs';
import { BreadcrumbService } from './utils/breadcrumb/breadcrumb.service';
import { CalculatorService, ClientCombo } from './services/calculator.service';
import { BibliotecaService } from './services/biblioteca.service';
import { LoadingService } from './services/loading.service';
import { Client, DefaultResponse, Person, Producer } from './services/domains/calculator.domain';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { MigrandoComponent } from './components/migrando/migrando.component';
import { PremiumService } from './components/premium/premium.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { SupportService } from './services/support.service';
import { FormBuilder, Validators } from '@angular/forms';
import { SupportTicketIn } from './services/domains/support.domain';
import { SimulatorService } from './services/simulator.service';
import { ProductividadService } from './services/productividad.service';
import { PlatformService } from './services/platform.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent implements AfterViewInit, OnInit {
  menu: HTMLElement | null = null;
  logo: HTMLElement | null = null;
  image: string = '../assets/icons/logo.svg';
  paths: string[] = []
  open: boolean = false;
  inMap: boolean = false;
  isLogged: boolean = false;
  migrating: number = 0;
  migrated: number = 0;
  advOpt = false;
  signupModal: boolean = false;

  bradcrumbUrls: { label: string; url: string }[] = [];

  loadingScreen: boolean = false;

  private destroy$ = new Subject<void>();
  clientName = '';
  maintenance: boolean = false;

  roles: string[] = [];
  canMigrate: boolean = false;
  showCleanDataButton = false;

  openSupportModal: boolean = false;
  roleToSupport: string = '';
  redirectAfterSupport: string = '';
  sendingTicket: boolean = false;

  openSupportForm: boolean = false;
  supportName: string = '';
  supportEmail: string = '';
  supportMessage: string = '';

  isGaliciaDemoUser = false;



  constructor(private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly calcSrv: CalculatorService,
    private readonly simSrv : SimulatorService,
    private readonly biblioSrv: BibliotecaService,
    private readonly loadingSrv: LoadingService,
    private readonly authS: AuthService,
    private readonly premiumSrv: PremiumService,
    private readonly prdSrv: ProductividadService,
    private config: PrimeNGConfig,
    private readonly supportSrv: SupportService,
    private readonly messageService: MessageService,
    private readonly fb: FormBuilder,
    private readonly platformSrv: PlatformService) {
    this.showCleanDataButton = environment.showCleanData;
    this.config.setTranslation({
      startsWith: 'Comienzan con',
      contains: 'Contienen',
      notContains: 'No contienen',
      endsWith: 'Terminan con',
      equals: 'Son iguales a',
      notEquals: 'Son diferentes a',
      matchAll: 'Todos',
      matchAny: 'Algunos',
      addRule: 'Agregar filtro',
      clear: 'Borrar',
      apply: 'Aplicar',
    });
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((d) => {
      this.setUrl();
    })
    this.breadcrumbService.getBreadcrumbs().subscribe(breadcrumbs => {
      this.bradcrumbUrls = breadcrumbs;
    })
    this.loadingSrv.loading$.subscribe({
      next: (v) => {
        this.loadingScreen = v;
      }
    })
    this.isLogged = this.authS.isLogged();
    if (this.isLogged) {
      this.getMe();
    }
    this.premiumSrv.getStatus().subscribe(d => {
      this.maintenance = d.maintenance;
      if (d.maintenance) {
        this.router.navigateByUrl('/mantenimiento');
      }
    });

    this.supportSrv.observableOpenSupportModal().subscribe(supportModal => {
      this.openSupportModal = supportModal.openModal ?? false;
      this.roleToSupport = supportModal.role ?? '';
      if (supportModal.redirect) {
        this.redirectAfterSupport = supportModal.redirect;
      }
    });

    this.supportSrv.observableOpenConsultModal().subscribe(isOpen => {
      this.openSupportForm = isOpen;
    });

  }

  formSupport = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    message: ['', Validators.required],
  })

  requestPermission() {
    this.sendingTicket = true;
    this.supportSrv.supportForRoles(this.roleToSupport).subscribe({
      next: () => {
        this.sendingTicket = false;
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Solicitud a soporte enviada con éxito.' });
        this.openSupportModal = false;
        if (this.redirectAfterSupport) {
          this.router.navigate([this.redirectAfterSupport]);
        }
      },
      error: () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo enviar la solicitud de soporte.' });
        this.openSupportModal = false;
        this.sendingTicket = false;
        if (this.redirectAfterSupport) {
          this.router.navigate([this.redirectAfterSupport]);
        }
      }
    })
  }

  migrando = false;
  migrandoErrors = false;
  @ViewChild('migrandoComponent') migrandoComponent?: MigrandoComponent;
  getMe() {
    this.calcSrv.getCalculatorMe().pipe(take(1)).subscribe({
      next: (res) => {
        this.setMe(res.payload)
        if(res.payload.galicia){
          this.isGaliciaDemoUser = true;
          sessionStorage.setItem('isDemo', 'true');
          this.router.navigateByUrl('/map-studio');
        } else {
          sessionStorage.clear();
        }
      },
      error: (err) => {
        this.migrating = 0;
        if (err.status === 404) {
          this.signupModal = true;
        } else if (err.status === 409) {
          this.migrandoErrors = true;
        } else if (err.status === 422) {
          this.migrando = true;
          this.migrandoComponent?.retryMe().then((res: Person) => {
            this.setMe(res);
          });
        } else {
          this.router.navigateByUrl('/error', { skipLocationChange: true, state: { err } });
          console.error(err);
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  startInterval() {
    setTimeout(() => {
      this.getMe();
    }, environment.intervalMigratingRequest); // esperamos mas para saber esto
  }

  setMe(person: Person) {
    this.users = person;
    this.users.clients = this.users.clients.sort((a, b) => a.razonSocial.localeCompare(b.razonSocial));
    this.users.clients.forEach((client: Client) => {
      client.producers = client.producers.sort((a, b) => a.name.localeCompare(b.name));
    });
    localStorage.setItem('user', JSON.stringify(this.users));
    this.calcSrv.setConfig(this.users!.country, this.users!.pay);
    this.migrating = person.migrating;
    this.migrated = person.migratedAmount;
    this.formSupport.patchValue({ name: person.name ? person.name + '' + person.lastName : '', email: person.email ? person.email : '' });

    if (person.migrating) {
      this.startInterval();
    }
    const mc = this.calcSrv.makeClientProducerList(person).sort((a, b) => a.productor.localeCompare(b.productor));
    if (mc.length != this.productorClientComb.length) {
      this.productorClientComb = mc.sort((a, b) => a.cliente.localeCompare(b.cliente));
      this.productorClientCombFiltered = this.productorClientComb;
      if (this.productorClientComb.length > 0) { // si hay algo en la lista
        // const pc = { producerID: person.selectedProducer ?? 0 , clientID: person.selectedClient ?? 0 } // vemos cual tenemos seleccionado
        const pc = this.calcSrv.getClientProducerSelected(); // ROLLBACK
        if (pc) {
          // si encontramos uno lo marcamos
          const selected = this.productorClientComb.find((pcFind) => pcFind.producerID == pc.producerID && pcFind.clientID == pc.clientID);
          if (selected) {
            this.selectedProducerClient = selected;
            this.saveProducerClientSelection(false);
            return;
          }
        }
        this.selectedProducerClient = this.productorClientComb[0];
        this.saveProducerClientSelection(false);
      }
    }

  }

  signupDone() {
    this.signupModal = false;
    localStorage.removeItem('producerClient');
    this.getMe();
  }

  changePlan() {
    this.premiumSrv.openModal('nav-bar');
  }

  login() {
    this.authS.goToSSO();
  }

  ngOnInit() {
    this.authS.setUserRoles();
    this.roles = this.authS.getUserRoles();
    if (this.roles.includes('ROLE_MIGRATIONS')) {
      this.canMigrate = true;
    }
    //Use NavigationEnd to detect when the component is destroyed
/*     this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Remove the breadcrumb when the component is destroyed
        this.breadcrumbService.removeLastBreadcrumb();
      }); */
  }

  handleClear() {
    this.biblioSrv.deleteStorage();
  }

  logout() {
    event?.preventDefault();
    localStorage.removeItem('jwt');
    localStorage.removeItem('producerClient');
    sessionStorage.clear();
    window.location.href = `${environment.ssoLocation}/logout`;
  }

  setUrl() {
    this.paths = window.location.pathname.split('/').filter(path => !path.match(/^\d+$/));
    this.paths.shift();
    this.paths.unshift('Inicio');
    if (this.paths.includes('mapa')) {
      this.inMap = true;
    } else {
      this.inMap = false;
    }
  }


  ngAfterViewInit(): void {
    this.menu = document.getElementById('menu');
    this.logo = document.getElementById('logo');
    if (this.authS.isLogged()) {
      setTimeout(() => {
        this.clientName = this.calcSrv.getClientProducerSelected().cliente;
      }, 500);
    }
  }

  viewMenu() {
    this.open = !this.open;
    this.calcSrv.menuOpen.next(this.open);
    this.menu?.classList.toggle('widthMenu');
    this.logo?.classList.toggle('full-logo');
  }

  usersModal: boolean = false;
  users: Person | undefined = undefined;
  selectedProducerClient: ClientCombo = {} as ClientCombo;
  openUsersModal() {
    this.usersModal = true;
  }


  productorClientComb: ClientCombo[] = [];
  productorClientCombFiltered: ClientCombo[] = [];
  saveProducerClientSelection(navigate?: boolean) {
    this.calcSrv.setClientProducerSelected(this.selectedProducerClient);
    this.clientName = this.selectedProducerClient.cliente;
    if (navigate) {
      const isReload = window.location.pathname.includes('/analitica') || window.location.pathname.includes('/charts') || window.location.pathname.includes('/certs');
      if (isReload) {
        window.location.reload();
      } else if(window.location.pathname.includes('/calculadora')){
        this.router.navigate(['/carbon-studio/biblioteca-calculo']);
      } else if (!window.location.pathname.includes('/biblioteca-calculo')) {
          if (window.location.pathname.endsWith('/cft')) {
            this.router.navigate(['/carbon-studio/biblioteca-calculo/cft']);
          } else if(/*window.location.pathname.includes('/calculadora') || */ window.location.pathname.includes('/map-studio')) {
            this.router.navigate(['/home']);
          } else if(window.location.pathname.includes('/monitoreo')) {
            // if(window.location.pathname.includes('/monitoreo/campo')) {
            //   window.location.reload();
            // } else {
            //   this.router.navigate(['/monitoreo/campo']);
            //   window.location.reload();
            // }
            this.platformSrv.emitChangeProducerTupla(window.location.pathname);
          }
      }
    }
    this.calcSrv.reloadFields.next(true);
    this.simSrv.reloadFields.next(true);
    this.prdSrv.reloadFilters.next(true);
    this.usersModal = false;
  }

  searchFilterApply(input: any) {
    this.productorClientCombFiltered = this.productorClientComb.filter((pc) =>
      pc.cliente.toLocaleLowerCase().includes(input.value.toLocaleLowerCase()) || pc.productor.toLocaleLowerCase().includes(input.value.toLocaleLowerCase())
    )
  }

  fullSync() {
    let conf = confirm('Está seguro que desea sincronizar todos los usuarios?');
    if (conf) {
      this.calcSrv.fullSync().pipe(take(1)).subscribe({
        next: (res) => {
          alert('Se inició la migración');
        },
        error: (err) => {
          console.error(err);
          alert('Error al iniciar migración');
        }
      });
    }
  }

  sync() {
    let email = prompt('Ingrese mail a migrar');
    // if(!email) {
    //   email = 'erika.l.viel@gmail.com';
    // }
    if (email) {
      setTimeout(() => {
        this.getMe();
      }, 600);
      this.calcSrv.sync(email).pipe(take(1)).subscribe({
        next: (res) => {
          // alert('Se inició la migración');
        },
        error: (err) => {
          console.error(err);
          // alert('Error al iniciar migración');
        }
      })
    }
  }

  openContactSupport() {
    this.openSupportForm = true;
  }

  sendSupportMessage() {
    this.sendingTicket = true;
    const ticket = this.buildTicketClass();
    this.supportSrv.sendFormToSupport(ticket).subscribe({
      next: () => {
        this.sendingTicket = false;
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Solicitud a soporte enviada con éxito.' });
        this.openSupportForm = false;
      },
      error: () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo enviar la solicitud de soporte.' });
        this.sendingTicket = false;
      }
    })
  }

  buildTicketClass() {
    const ticketIn = new SupportTicketIn();
    ticketIn.email = this.formSupport.get('email')?.value!;
    ticketIn.message = this.formSupport.get('message')?.value!;
    ticketIn.name = this.formSupport.get('name')?.value!;
    return ticketIn;
  }

  resetSupportForm() {
    this.formSupport.reset();
  }

}

